import React, { useEffect, useRef } from "react";
import Rellax from "rellax";
import { cn } from "../lib/helpers";
import { responsiveTitle1, bodyTextBlock } from "./typography.module.css";
import backgroundImage from "../images/case-studies-bg.png";
import logoBlank from "../images/capacity-logo-blank.svg";
import { useMediaQuery } from "react-responsive";
import { mediaMaxSmall } from "../lib/mediaQueries";

import PortableText from "./portableText";

import styles from "./our-work.module.css";

function OurWork(props) {
  const { title, closingCopy, children } = props;
  const isMobile = useMediaQuery(mediaMaxSmall);

  const backgroundRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (!isMobile) {
      const rellaxOptions = {
        center: true,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false,
      };
      const backgroundRellax = new Rellax(backgroundRef.current, {
        ...rellaxOptions,
        speed: -2,
      });
      const contentRellax = new Rellax(contentRef.current, {
        ...rellaxOptions,
        speed: 0.5,
      });
      const rellaxInstances = [backgroundRellax, contentRellax];
      return () => {
        //reset for page changes
        rellaxInstances.forEach((instance) => {
          instance.destroy();
        });
      };
    }
  });

  return (
    <div className={cn(styles.root)}>
      <div ref={backgroundRef} className={styles.bgImageWrapper}>
        <img className={styles.bgImage} src={backgroundImage} alt="Capacity.gg" />
      </div>
      <div className={styles.content} ref={contentRef}>
        {title && <h2 className={cn(responsiveTitle1, styles.title)}>{title}</h2>}

        {children}

        {closingCopy && (
          <div className={cn(styles.bottomCopy, bodyTextBlock)}>
            <div className={styles.bottomCopyInner}>
              <PortableText blocks={closingCopy} />
              <div className={styles.signature}>Love, Capacity &#60;3</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

OurWork.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default OurWork;
