import { WebGLRenderer, PerspectiveCamera, Color, Scene, Vector3 } from "three";

export default class Engine {
  constructor(w, h, { backgroundColor, z = 2 } = {}) {
    this.width = w;
    this.height = h;
    this.meshCount = 0;
    this.meshListeners = [];
    this.frameId = null;
    // console.log(window.devicePixelRatio);
    // this.devicePixelRatio = window.devicePixelRatio ? Math.min(1.6, window.devicePixelRatio) : 1;
    this.devicePixelRatio = 1;

    this.renderer = new WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setPixelRatio(this.devicePixelRatio);
    if (backgroundColor !== undefined) this.renderer.setClearColor(new Color(backgroundColor));
    this.scene = new Scene();

    this.camera = new PerspectiveCamera(50, this.width / this.height, 1, 1000);
    this.camera.position.set(0, 0, z);

    this.dom = this.renderer.domElement;

    this.update = this.update.bind(this);
    this.resize = this.resize.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);

    // Put automaticaly the canvas in background
    this.dom.style.position = "absolute";
    this.dom.style.top = "0";
    this.dom.style.left = "0";

    this.resize(w, h);

    //camera orbit

    this.cameraAmpl = { x: 0.3, y: 0.3 };
    this.cameraVelocity = 0.03;
    this.lookAt = new Vector3();

    this.mousePosition = { x: 0, y: 0 };
    this.normalizedOrientation = new Vector3();

    window.addEventListener("mousemove", this.handleMouseMove);
  }

  //events

  handleMouseMove(event) {
    this.mousePosition.x =
      event.clientX || (event.touches && event.touches[0].clientX) || this.mousePosition.x;
    this.mousePosition.y =
      event.clientY || (event.touches && event.touches[0].clientY) || this.mousePosition.y;
    this.normalizedOrientation.set(
      -(this.mousePosition.x / this.width - 0.5) * this.cameraAmpl.x,
      (this.mousePosition.y / this.height - 0.5) * this.cameraAmpl.y,
      0.5
    );
  }

  /**
   * * *******************
   * * SCENE MANAGMENT
   * * *******************
   */
  add(mesh) {
    this.scene.add(mesh);
    if (!mesh.update) return;
    this.meshListeners.push(mesh.update);
    this.meshCount++;
  }
  remove(mesh) {
    this.scene.remove(mesh);
    if (!mesh.update) return;
    const index = this.meshListeners.indexOf(mesh.update);
    if (index > -1) this.meshListeners.splice(index, 1);
    this.meshCount--;
  }

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.update);
    }
  }

  // Update render
  update() {
    // console.log(this);
    let i = this.meshCount;
    while (--i >= 0) {
      this.meshListeners[i].apply(this, null);
    }
    //update camera orbit
    this.camera.position.x +=
      (this.normalizedOrientation.x - this.camera.position.x) * this.cameraVelocity;
    this.camera.position.y +=
      (this.normalizedOrientation.y - this.camera.position.y) * this.cameraVelocity;
    this.camera.lookAt(this.lookAt);

    // Loop
    this.render();
    this.frameId = window.requestAnimationFrame(this.update);
  }
  stop() {
    cancelAnimationFrame(this.frameId);
    this.frameId = null;
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }

  // Resize
  resize(w, h) {
    this.width = w;
    this.height = h;
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
    this.resizeRender();
  }

  resizeRender() {
    this.renderer.setSize(this.width, this.height);
    if (this.composer) this.composer.setSize(this.width, this.height);
  }
}
