import React, { useState } from "react";
import ProjectPreviewGrid from "./project-preview-grid";
import OurWork from "./our-work";
import Services from "./services";
import Intro from "./intro";
import ClientsList from "./clients-list";
import Slider from "./slider";
import ImageCard from "./image-card";

const HomePage = (props) => {
  const {
    introTitleCopy,
    aboutButtonCopy,
    _rawAboutCopy,
    aboutCopy,
    clientsTitleCopy,
    clientsSubtitleCopy,
    clientsAfterCopy,
    desktopLogoGrid,
    mobileLogoGrid,
    projectCards,
    selectedProjects,
    caseStudiesTitleCopy,
    _rawClosingCopy,
    closingCopy,
    caseStudiesReadMoreCopy,
    splashPageEnabled,
    introBackgroundVideo,
    introLottieMobile,
    introLottieDesktop,
  } = props;
  const aboutCopyBlocks = _rawAboutCopy ? _rawAboutCopy : aboutCopy;
  const closingCopyBlocks = _rawClosingCopy ? _rawClosingCopy : closingCopy;

  return (
    <main>
      <Services
        splashPageEnabled={splashPageEnabled}
        introLottieMobile={introLottieMobile}
        introLottieDesktop={introLottieDesktop}
      />
      <Intro
        title={introTitleCopy}
        buttonCopy={aboutButtonCopy}
        body={aboutCopyBlocks}
        introBackgroundVideo={introBackgroundVideo}
      />
      <ClientsList
        title={clientsTitleCopy}
        subtitle={clientsSubtitleCopy}
        afterCopy={clientsAfterCopy}
        desktopLogos={desktopLogoGrid}
        mobileLogos={mobileLogoGrid}
      />
      {(projectCards || selectedProjects) && (
        <OurWork title={caseStudiesTitleCopy} closingCopy={closingCopyBlocks}>
          {selectedProjects && (
            <ProjectPreviewGrid
              buttonCopy={caseStudiesReadMoreCopy}
              nodes={selectedProjects}
              browseMoreHref="/work/"
            />
          )}
          {projectCards && (
            <Slider>
              {projectCards.map((project, index) => {
                return (
                  <ImageCard
                    image={project.desktopImage}
                    mobileImage={project.mobileImage}
                    title={project.title}
                    key={index}
                    centered
                  />
                );
              })}
            </Slider>
          )}
        </OurWork>
      )}
    </main>
  );
};

export default HomePage;
