import React, { useEffect, useRef } from "react";
import * as breakpoints from "../lib/breakpoints";
import styles from "./stars.module.css";

import { Color, Vector3, SphereBufferGeometry, Mesh, Raycaster, MeshBasicMaterial } from "three";
import Engine from "../lib/webgl/utils/engine";
import LineGenerator from "../lib/webgl/objects/LineGenerator";
import getRandomFloat from "../lib/webgl/utils/getRandomFloat";

// import HandleCameraOrbit from "../lib/webgl/decorators/HandleCameraOrbit";

function Stars(props) {
  const mount = useRef(null);

  let starDrawingRange = 3;
  let starWidthRange = { min: 0.01, max: 0.04 };

  const setSceneProperties = (sceneWidth) => {
    if (sceneWidth < breakpoints.small) {
      // mobile
      starDrawingRange = 2;
      starWidthRange.min = 0.04;
      starWidthRange.max = 0.06;
    } else if (sceneWidth < breakpoints.largest) {
      // desktop
      starDrawingRange = 3;
      starWidthRange.min = 0.01;
      starWidthRange.max = 0.04;
    } else if (sceneWidth >= breakpoints.largest) {
      // huge
      starDrawingRange = 6;
      starWidthRange.min = 0.005;
      starWidthRange.max = 0.015;
    }
  };

  useEffect(() => {
    setSceneProperties(mount.current.clientWidth);
    /**
     * * *******************
     * * ENGINE
     * * *******************
     */

    // @HandleCameraOrbit({ x: 1, y: 1 }, 0.1)
    class CustomEngine extends Engine {}
    const engine = new CustomEngine(
      mount.current.clientWidth,
      mount.current.clientHeight,
      mount.current
    );
    engine.camera.position.z = 2;

    /**
     * * *******************
     * * LINES
     * * *******************
     */

    const radius = 6.5;
    const origin = new Vector3();
    const direction = new Vector3();
    const raycaster = new Raycaster();
    // const geometry = new SphereBufferGeometry(radius, 32, 32, 0, 3.2, 4, 2.1);
    const geometry = new SphereBufferGeometry(radius, 32, 32, 0, 3.2, 3.6, 2.1);

    const material = new MeshBasicMaterial({ wireframe: true, visible: false });
    const sphere = new Mesh(geometry, material);
    sphere.position.z = 0.5;
    // sphere.position.y = 1;
    engine.add(sphere);

    const COLORS = ["#e5ffbd"].map((col) => new Color(col));
    const STATIC_PROPS = {
      transformLineMethod: (p) => p,
    };

    class CustomLineGenerator extends LineGenerator {
      addLine() {
        //angle which lines will change while going across the screen
        let incrementation = 0;

        //range of starting points on x axis
        let x = getRandomFloat(-radius * starDrawingRange, radius * starDrawingRange);

        //angle
        //starting and end range of where lines are drawn
        let a = (Math.PI * -25) / 180;
        let aMax = (Math.PI * 300) / 180;

        const points = [];
        while (a < aMax) {
          a += 0.2;
          x -= incrementation;
          origin.set(x, radius * Math.cos(a), radius * Math.sin(a));

          //switch this around to change the direction of the lines
          direction.set(-origin.x, 0, -origin.z);
          direction.normalize();
          raycaster.set(origin, direction);

          // save the points
          const intersect = raycaster.intersectObject(sphere, true);
          if (intersect.length) {
            points.push(intersect[0].point.x, intersect[0].point.y, intersect[0].point.z);
          }
        }

        if (points.length === 0) return;

        // Fast lines
        super.addLine({
          visibleLength: getRandomFloat(0.1, 0.35),
          points,
          speed: getRandomFloat(0.004, 0.035),
          color: COLORS[0],
          width: getRandomFloat(starWidthRange.min, starWidthRange.max),
        });
      }
    }
    const lineGenerator = new CustomLineGenerator(
      {
        frequency: 1,
      },
      STATIC_PROPS
    );

    //kick it off
    engine.add(lineGenerator);
    mount.current.appendChild(engine.dom);
    engine.start();
    lineGenerator.start();

    //resize
    const handleResize = () => {
      if (mount.current) {
        const width = mount.current.clientWidth;
        const height = mount.current.clientHeight;
        engine.resize(width, height);

        setSceneProperties(width);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      //unmount
      engine.stop();
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.starScene} ref={mount}></div>
    </div>
  );
}

export default Stars;
