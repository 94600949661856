import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { cn } from "../lib/helpers";

import styles from "./shield-hero.module.css";

function ShieldHero(props) {
  const mount = useRef(null);
  const [animationActions, setAnimationActions] = useState(null);

  const controls = useRef(null);

  useEffect(() => {
    let width = mount.current.clientWidth;
    let height = mount.current.clientHeight;
    let frameId;
    let model;
    let mixer;
    let clock = new THREE.Clock();

    const MODEL_PATH = "/models/CAPA1001_EG_Capacity_Logo_Anim_039.glb";

    //init scene
    const scene = new THREE.Scene();

    //init renderer
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.shadowMap.enabled = true;
    // renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setPixelRatio(1);

    //add a camera

    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);

    camera.aspect = 1;

    camera.far = 1000;
    camera.near = 0.10000000149011612;
    camera.filmGauge = 35;
    camera.filmOffset = 0;
    camera.focus = 10;
    camera.zoom = 1;
    camera.position.z = 10;
    camera.setFocalLength(50);

    //load model
    var loader = new GLTFLoader();

    loader.load(
      MODEL_PATH,
      function (gltf) {
        // A lot is going to happen here

        model = gltf.scene;
        let fileAnimations = gltf.animations;

        model.traverse((o) => {
          if (o.isMesh) {
            o.castShadow = true;
            o.receiveShadow = true;
          }
        });

        const MODEL_SCALE = 5.15;
        // Set the models initial scale
        model.scale.set(MODEL_SCALE, MODEL_SCALE, MODEL_SCALE);
        model.position.y = 0.28;
        // model.position.x = -0.2;

        scene.add(model);

        mixer = new THREE.AnimationMixer(model);

        let actions = {};
        fileAnimations.forEach((anim) => {
          let clip = THREE.AnimationClip.findByName(fileAnimations, anim.name);
          clip = mixer.clipAction(clip);

          actions[anim.name] = clip;
        });

        setAnimationActions(actions);
      },
      undefined, // We don't need this function
      function (error) {
        console.error(error);
      }
    );

    //add lights
    let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.61);
    hemiLight.position.set(0, 50, 0);
    // Add hemisphere light to scene
    scene.add(hemiLight);

    let d = 8.25;
    let dirLight = new THREE.DirectionalLight(0xffffff, 0.54);
    dirLight.position.set(-7, 5, 14);
    // dirLight.castShadow = true;
    // dirLight.shadow.mapSize = new THREE.Vector2(2048, 2048);
    // dirLight.shadow.camera.near = 0.1;
    // dirLight.shadow.camera.far = 1500;
    // dirLight.shadow.camera.left = d * -1;
    // dirLight.shadow.camera.right = d;
    // dirLight.shadow.camera.top = d;
    // dirLight.shadow.camera.bottom = d * -1;
    // Add directional Light to scene
    scene.add(dirLight);

    // Shadow Floor

    // let floorGeometry = new THREE.PlaneGeometry(5000, 5000, 1, 1);
    // let floorMaterial = new THREE.ShadowMaterial();
    // floorMaterial.opacity = 0.3;
    // let floor = new THREE.Mesh(floorGeometry, floorMaterial);
    // floor.rotation.x = 0;
    // floor.receiveShadow = true;
    // floor.position.z = -1.25;
    // floor.position.y = 0;
    // scene.add(floor);

    renderer.setSize(width, height);

    const renderScene = () => {
      renderer.render(scene, camera);
    };

    const handleResize = () => {
      width = mount.current.clientWidth;
      height = mount.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderScene();
    };

    const update = () => {
      if (mixer) {
        mixer.update(clock.getDelta());
      }
      renderScene();
      frameId = window.requestAnimationFrame(update);
    };

    const start = () => {
      if (!frameId) {
        frameId = requestAnimationFrame(update);
      }
    };

    const stop = () => {
      cancelAnimationFrame(frameId);
      frameId = null;
    };

    mount.current.appendChild(renderer.domElement);
    window.addEventListener("resize", handleResize);
    start();

    controls.current = { start, stop };

    return () => {
      //unmount
      stop();
      window.removeEventListener("resize", handleResize);
      mount.current.removeChild(renderer.domElement);

      scene.remove(model);
    };
  }, []);

  const playOnClick = (animationName) => {
    const action = animationActions[animationName];
    action.setLoop(THREE.LoopOnce);
    action.reset();
    action.play();
  };
  const playOnHover = (animationName) => {
    const action = animationActions[animationName];
    action.reset();
    action.timeScale = 1;
    action.setLoop(THREE.LoopOnce);
    action.clampWhenFinished = true;
    action.play();
  };

  const playOnHoverReverse = (animationName) => {
    const action = animationActions[animationName];
    if (action.time === 0) {
      action.time = action.getClip().duration;
    }
    action.paused = false;
    action.setLoop(THREE.LoopOnce);
    action.timeScale = -1;
    action.clampWhenFinished = false;
    action.play();
  };

  return (
    <div className={styles.root}>
      <div className={styles.buttonsWrapper}>
        <button
          className={cn(styles.shieldButton, styles.shieldButtonUp)}
          onMouseDown={() => playOnClick("Rotate_UP")}
          onMouseEnter={() => playOnHover("Button_UPAction")}
          onMouseLeave={() => playOnHoverReverse("Button_UPAction")}
          aria-label="Tilt Shield Up"
        >
          Up
        </button>

        <button
          className={cn(styles.shieldButton, styles.shieldButtonRight)}
          onMouseDown={() => playOnClick("Rotate_RIGHT")}
          onMouseEnter={() => playOnHover("Button_RIGHTAction")}
          onMouseLeave={() => playOnHoverReverse("Button_RIGHTAction")}
          aria-label="Tilt Shield Right"
        >
          Right
        </button>

        <button
          className={cn(styles.shieldButton, styles.shieldButtonDown)}
          onMouseDown={() => playOnClick("Rotate_DOWN")}
          onMouseEnter={() => playOnHover("Button_DOWNAction")}
          onMouseLeave={() => playOnHoverReverse("Button_DOWNAction")}
          aria-label="Tilt Shield Down"
        >
          Down
        </button>

        <button
          className={cn(styles.shieldButton, styles.shieldButtonLeft)}
          onMouseDown={() => playOnClick("Rotate_LEFT")}
          onMouseEnter={() => playOnHover("Button_LEFTAction")}
          onMouseLeave={() => playOnHoverReverse("Button_LEFTAction")}
          aria-label="Tilt Shield Left"
        >
          Left
        </button>
      </div>
      <div className={styles.shieldScene} ref={mount}></div>
    </div>
  );
}

export default ShieldHero;
